<template>
  <div>
    <b-row v-if="access.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="access"
            :fields="fields"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(is_active)="data">
              <span v-if="data.item.is_active"> Активен </span>
              <span v-else> Не активен </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
  data() {
    return {
      id: this.$route.query.access_id,
      access: undefined,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "ID", sortable: true, thStyle: { width: "150px" } },
        {
          key: "name",
          label: "Доступы",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "is_active",
          label: "Активность",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    resizeable();
    this.$http.get(`position/${this.id}/edit`).then((res) => {
      this.access = res.data.accesses;
      this.$store.commit("pageData/setdataCount", this.access.length);
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
